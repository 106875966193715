import APP_CONFIG from "./getAppConfig";
import CHECK_CODE from "./checkCodeValidity";
import FORGOT_PASSWORD from "./forgotPassword";
import POST_LOGOUT from "./logout";
import POST_REFRESH from "./refreshAccessToken";
import POST_SIGNIN from "./signIn";
import VALIDATE_EMAIL from "./secureSignup/validateEmail";
import VALIDATE_RUT from "./secureSignup/validateRut";
import GENERATE_SIGNUP_TOKEN from "./secureSignup/generateSignupToken";

export {
  APP_CONFIG,
  CHECK_CODE,
  FORGOT_PASSWORD,
  POST_LOGOUT,
  POST_REFRESH,
  POST_SIGNIN,
  VALIDATE_EMAIL,
  VALIDATE_RUT,
  GENERATE_SIGNUP_TOKEN,
};
