"use client";

import { Loading } from "components/system";
import { useAuthGuard } from "contexts/auth/authGuard";
import useNotifications from "hooks/account/profile/useNotifications";
import useProfileMeter from "hooks/account/profile/useProfileMeter";
import { createContext, useContext, useMemo } from "react";
import updateRefreshToken from "./refreshToken";
import useUserInfo from "./userInfo";
import useUserStore from "./userStore";
import PropTypes from "prop-types";

const AuthContext = createContext();

const AuthProvider = ({ children, fingerprint }) => {
  const { updateToken, userId } = updateRefreshToken();
  const { loading, refreshUserStore, userStore } = useUserStore(userId);
  const userInfo = useUserInfo(userId);
  const notifications = useNotifications(userId);
  const validations = useProfileMeter(userId);
  const isMounted = useAuthGuard();

  return (
    <AuthContext.Provider
      value={useMemo(() => {
        return Object.assign({}, userInfo, userStore, {
          disabled: !userId || !userStore?.interests.length,
          loading: userInfo.loading || loading,
          notifications,
          refreshUserStore,
          uid: userId,
          updateToken,
          validations,
          fingerprint,
        });
      }, [userId, userInfo, userStore, refreshUserStore, fingerprint])}
    >
      {isMounted ? children : <Loading />}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAuth = () => useContext(AuthContext) || {};

export { AuthProvider, useAuth };
