import { gql } from "@apollo/client";

const POST_SIGNIN = gql`
  query Signin($email: String!, $password: String!, $options: SigninOptions) {
    signin(email: $email, password: $password, options: $options) {
      accessToken
      refreshToken
    }
  }
`;

export default POST_SIGNIN;
