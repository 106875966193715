"use client";

import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { POST_LOGOUT, POST_REFRESH } from "queries/session";
import decodeToken from "contexts/auth/decodeToken";
import {
  getRefreshToken,
  isExpired,
  logout,
  getAuthStore,
  setLoading,
} from "contexts/store";

// Variable to prevent multiple token refreshes
let isRefreshingToken = false;

// Middleware to add authentication token to GraphQL requests
const authMiddleware = setContext(async (_, { headers }) => {
  // Get current refresh token and access token
  const currentRefreshToken = getRefreshToken();
  const { accessToken: currentAccessToken } = getAuthStore();

  if (currentRefreshToken && isExpired() && !isRefreshingToken) {
    // Set isRefreshingToken to true to prevent multiple refreshes
    isRefreshingToken = true;
    // If there is a refresh token and it's expired, try to refresh it
    await refreshQuery(currentRefreshToken).then((data) => {
      const { accessToken, refreshToken } = data.refreshAccessToken;
      // Use token decoding to update session and user information
      decodeToken({ accessToken, refreshToken });

      return {
        headers: {
          ...headers,
          "x-user-agent": navigator.userAgent,
          authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      };
    });
  }

  // Return current headers with access token if present
  return {
    headers: {
      ...headers,
      "x-user-agent": navigator.userAgent,
      authorization: currentAccessToken ? `Bearer ${currentAccessToken}` : "",
    },
  };
});

// Configuration of Apollo Client for token refresh
const refreshApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({ uri: process.env.GRAPHQL_ENDPOINT }),
});

// Function to perform refresh token update
const refreshQuery = async (currentRefreshToken) => {
  try {
    const { data } = refreshApolloClient.mutate({
      mutation: POST_REFRESH,
      variables: { refreshToken: currentRefreshToken },
    });

    return data;
  } catch (error) {
    // Error handling during token refresh
    console.error("Error refreshing token:", error);
    // Logout in case of error and deactivate the token
    logout();
    await desactivateTokenQuery(currentRefreshToken);
    // Set loading state to false and return null
    setLoading(false);
    return null;
  } finally {
    // Set isRefreshingToken to false to allow token refresh
    isRefreshingToken = false;
  }
};

// Function to deactivate the authentication token
const desactivateTokenQuery = async (refreshToken) => {
  try {
    await refreshApolloClient.mutate({
      mutation: POST_LOGOUT,
      variables: { refreshToken },
    });
  } catch (error) {
    // Error handling during token deactivation
    console.error("Error deactivating token:", error);
  }
};

/**
 * Configuration of the Apollo Client for server-side rendering with authentication middleware.
 * Utilizes the experimental Next.js Apollo Client support.
 *
 * @returns {Object} An object containing the Apollo Client instance and associated functions.
 * @property {Function} getClient - Function to retrieve the configured Apollo Client instance.
 */
export const getClient = () => {
  // Configuration of Next.js Apollo Client with authentication middleware
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authMiddleware.concat(
      new HttpLink({
        uri: process.env.GRAPHQL_ENDPOINT,
      })
    ),
  });
};

// Function to initialize Apollo Client
export function createApolloClient(initialState = null) {
  // Check if Apollo Client is already initialized
  if (typeof window === "undefined") {
    return getClient();
  }

  // Create Apollo Client if not present
  if (!window.apolloClient) {
    const client = getClient();
    if (initialState) {
      client.cache.restore(initialState);
    }
    window.apolloClient = client;
  }

  return window.apolloClient;
}
