import { gql } from "@apollo/client";

const VALIDATE_RUT = gql`
  query ValidateRut(
    $rut: String!
    $fingerprint: String!
    $recaptchaToken: String!
  ) {
    validateRut(
      rut: $rut
      fingerprint: $fingerprint
      recaptchaToken: $recaptchaToken
    ) {
      success
      status
      message
      data
    }
  }
`;

export default VALIDATE_RUT;
